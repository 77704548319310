import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Japan",
  "subtitle": "2010, 2013, 2018",
  "category": "Asia"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "general"
    }}>{`General`}</h2>
    <ul>
      <li parentName="ul">{`SIM - Getting an eSim is the way to go these days! Compare prices online. Wifi can be difficult to find but there's always `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Manga_cafe"
        }}>{`manga cafes`}</a>{` when in need.`}</li>
      <li parentName="ul">{`Money - Prefer Cash over card. Post office has ATMs.`}</li>
      <li parentName="ul">{`Coin Lockers - Paid storage but almost all train stations and airports have it`}</li>
      <li parentName="ul">{`Free Tissues - Advertisements but skip the black ones... they bleed!`}</li>
      <li parentName="ul">{`Koi fish - Don't feed the ones inside a store's pond! They don't like it but I've seen people feed at temples and rivers...`}</li>
      <li parentName="ul">{`Shoes - Look out for signs to take off your shoes!`}</li>
      <li parentName="ul">{`Trash - Bring a plastic bag since there aren't trash bins on the streets. However, convenient stores usually have them.`}</li>
    </ul>
    <h3 {...{
      "id": "getting-around"
    }}>{`Getting around`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.jrpass.com/"
        }}>{`JR Pass`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Only available to tourist but gives unlimited rail access across Japan. Available in 7, 14 and 21 days. Amazing value and I get it almost every time.`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "http://www.jrpass.com/map"
            }}>{`Useful map`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "http://www.hyperdia.com/en/"
            }}>{`Useful train timetable`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.jreast.co.jp/multi/en/top.html/"
        }}>{`JR EAST Pass`}</a>{` - More specific when based out of Tokyo`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.jreast.co.jp/multi/en/pass/nex.html"
            }}>{`N’EX Tokyo Round-trip ticket`}</a>{` - Value purchase to/from Narita airport`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "food"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Ramen#Regional_variations"
        }}>{`Ramen`}</a>{` - Try the regional specialties (see notes)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Oden"
        }}>{`おでん (Oden)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Okonomiyaki"
        }}>{`お好み焼き (Okonomiyaki)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Japanese_curry"
        }}>{`カレー (Curry)`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Try the regional specialties (see notes)`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://en.wikipedia.org/wiki/Katsu_curry"
            }}>{`カツカレー`}</a>{` - Pork culet with curry over rice`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://en.wikipedia.org/wiki/Curry_bread"
            }}>{`カレーパン`}</a>{` - Curry bread`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Takoyaki"
        }}>{`たこ焼き (Takoyaki)`}</a>{` - Famous in Osaka; I love Mentaiko and Cheese!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.mos.co.jp/global/"
        }}>{`モスバーガー (Mos Burger)`}</a>{` - Burger chain`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Monjayaki"
        }}>{`もんじゃ焼き (Monjayaki)`}</a>{` - Popular in Tokyo`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Conveyor_belt_sushi"
        }}>{`回転寿司 (Kaitenzushi)`}</a>{` - Conveyor belt sushi at various price points!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Ekiben"
        }}>{`駅弁 (Ekiben)`}</a>{` - Bento for your long train rides!`}</li>
      <li parentName="ul">{`European food - Similar to Americanized asian food, there's the reverse`}</li>
    </ul>
    <h4 {...{
      "id": "dessert"
    }}>{`Dessert`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://cheesetart.com/en/shop/"
        }}>{`BAKE Cheese Tart`}</a>{` - Hokkaido cheese tarts literally melt in your mouth`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.31ice.co.jp/"
        }}>{`Baskin Robins`}</a>{` - Everyone calls it 31`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.haagen-dazs.co.jp/"
        }}>{`Häagen-Dazs`}</a>{` - Exclusive limited flavors you'll never see outside of Japan. 紫いも (Purple Sweet Potato) is one of my favorites!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Kit_Kats_in_Japan"
        }}>{`Kit Kats`}</a>{` - Many flavors can be purchased online these days but some flavors are more local and harder to come by.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.misterdonut.jp/"
        }}>{`Mister Donut`}</a>{` - Known as ミスド ("Misdo”)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://tokyomilkcheese.jp/"
        }}>{`Tokyo Milk Cheese Factory`}</a>{` - I love everything they make!!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://web.pucchin.jp/info/"
        }}>{`プッチンプリ (Pucchin Purin)`}</a>{` - Honestly Glica anything!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Baumkuchen"
        }}>{`バウムクーヘン (Baumkuchen)`}</a>{` - A German cake but far more popular in Japan. Matcha is my favorite flavor.`}</li>
    </ul>
    <h4 {...{
      "id": "drinking"
    }}>{`Drinking`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Sake#Different_handling_after_fermentation"
        }}>{`生酒 (Namazake)`}</a>{` - Sake in general but espeically unpasteurized sake! Very popular in the spring right after brewing season. Highly recommend!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Izakaya"
        }}>{`居酒屋 (Izakaya)`}</a>{` - A casual bar with food that's very popular for after-work drinking`}</li>
    </ul>
    <h3 {...{
      "id": "convenience-stores"
    }}>{`Convenience stores`}</h3>
    <ul>
      <li parentName="ul">{`They’re everywhere and opened 24/7`}
        <ul parentName="li">
          <li parentName="ul">{`It’s like a mini supermarket and sell lots of inexpensive ready to eat meals`}</li>
          <li parentName="ul">{`7 Eleven, Lawson, Sunkus, Family Mart, Newsday, AM/PM, Kiosk, etc.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Vending Machines are everywhere`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "http://cp.glico.jp/17ice/"
            }}>{`Glica ice cream`}</a>{` - Often seen near train stations`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.lotte.co.jp/products/brand/coolish/"
            }}>{`Coolish ice cream`}</a>{` - They've expanded with many flavors`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "activities"
    }}>{`Activities`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Onsen"
        }}>{`温泉 (Onsen)`}</a>{` - Many old school places don't allow tattoos. Highly suggest the natural ones in the more rural areas.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Ashiyu"
        }}>{`足湯 (Ashiyu)`}</a>{` - Public foot bath that's usually free and often found near resting areas like train stations`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Karaoke"
        }}>{`カラオケ (Karaoke)`}</a>{` - Very common to rent a whole room. Serves food and drinks, and some have scoring systems etc.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Pachinko"
        }}>{`パチンコ (Pachinko)`}</a>{` - Pinball gambling that's very popular. It's very loud inside so maybe bring ear plugs!`}</li>
    </ul>
    <h3 {...{
      "id": "shopping"
    }}>{`Shopping`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://donki.com"
        }}>{`ドン・キホーテ (Don Quijote)`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Discount chain store in most major cities that sells literally everything you want to bring home`}</li>
          <li parentName="ul">{`There is a `}<a parentName="li" {...{
              "href": "https://maps.app.goo.gl/oqK43QVUBFQ4jB5y7"
            }}>{`MEGA branch`}</a>{` in Shibuya`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/100-yen_shop"
        }}>{`100円ショップ (¥100 shop)`}</a>{` - Better than 99 cent stores`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://en.wikipedia.org/wiki/Daiso"
            }}>{`Daiso`}</a>{` - Pretty much international at this point`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.seria-group.com/shop/"
            }}>{`Seria`}</a>{` - I like them more than Daiso`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.muji.com/jp/ja/store"
        }}>{`Muji`}</a></li>
    </ul>
    <h4 {...{
      "id": "cosmetics"
    }}>{`Cosmetics`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://us.cosme.net/"
        }}>{`@Cosme`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.matsukiyo.co.jp/"
        }}>{`Matsumoto Kiyoshi`}</a></li>
    </ul>
    <h2 {...{
      "id": "kanto---tokyo"
    }}>{`Kanto - Tokyo`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.jreast.co.jp/multi/en/suica/"
        }}>{`Suica Card`}</a>{` - For riding the metro. I'm not sure how the tourist one works but the local one is available `}<a parentName="li" {...{
          "href": "https://support.apple.com/en-us/HT207154"
        }}>{`digitally`}</a>{` and I've used it as an e-wallet in additional to the metro.`}</li>
    </ul>
    <h3 {...{
      "id": "excursions"
    }}>{`Excursions`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/rVsHuN8QEDgZiECPA"
        }}>{`ディズニーシー (Disney Sea)`}</a>{` - Unique to Japan and closer to Narita airport`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/krv85jEx5TVDkVrB7"
        }}>{`三鷹の森ジブリ美術館 (Ghibli Museum)`}</a>{` - Purchase tickets in advance. No photos and the films didn't have subtitles.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/gxHy4DBGf9qFpJ8W6"
        }}>{`中野ブロードウェイ (Nakano Broadway)`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Old school shopping center`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/EVNczcLf7uCuiBts8"
            }}>{`Daily Chiko`}</a>{` - 8 stack ice cream`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "秋葉原-akihabara"
    }}>{`秋葉原 (Akihabara)`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/aGiMdAC5rWf2yvn26"
        }}>{`ヨドバシカメラ (Yodobashi Camera)`}</a>{` - There are other branches but this one here is like 9 floors!`}</li>
    </ul>
    <h4 {...{
      "id": "themed-cafes"
    }}>{`Themed Cafes`}</h4>
    <ul>
      <li parentName="ul">{`Cats, Dogs, Gundam, Hello Kitty, Wedding, etc. Host Clubs are a thing too.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/ptWJJd2eBz9ntZww5"
        }}>{`めいどりーみん (MaiDreamin)`}</a>{` - Maid Cafe that has expanded lots since 2010`}</li>
    </ul>
    <h4 {...{
      "id": "arcades"
    }}>{`Arcades`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/vb5KZUGHYgbfS9yz8"
        }}>{`GiGO`}</a>{` - Used to be SEGA`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/vb5KZUGHYgbfS9yz8"
        }}>{`Super Potato`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/rj7oQLo3VECHcttp8"
        }}>{`TAITO’s Hey`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Claw_machine#In_Asia"
        }}>{`UFOキャッチャー (UFO Catcher)`}</a>{` - You can ask the attendent for help`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Gashapon"
        }}>{`ガシャポン (Gachapon)`}</a>{` - Good luck!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Photo_booth#Purikura_2"
        }}>{`プリクラ (Purikura)`}</a>{` - Sticker photos!`}</li>
    </ul>
    <h3 {...{
      "id": "浅草-asakura--nearby"
    }}>{`浅草 (Asakura) & nearby`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/a38vvdkjMvYoGcSS7"
        }}>{`仲見世商店街 (Nakamise-dori)`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Known as the “in-between street” with lots of food stalls and shopping`}</li>
          <li parentName="ul">{`人力車 - Rickshaw rides are popular with optional guided tour`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://en.wikipedia.org/wiki/Maneki-neko"
            }}>{`招き猫`}</a>{` - Maneki Neko souvenirs are popular`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/iqzA4HrmEv7w8nf27"
            }}>{`浅草寺`}</a>{` - Sensoji`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/6c7z1F7eFMJKabau8"
            }}>{`浅草神社`}</a>{` - Asakusa Shrine`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/Qy1KWCGQzgMhs1iW8"
        }}>{`アサヒグループ本社 (Asahi Headquarters)`}</a>{` - Known as `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Asahi_Beer_Hall"
        }}>{`うんこビル`}</a>{` (Poop building) due to the golden flame ornament shaped like a `}<a parentName="li" {...{
          "href": "https://maps.app.goo.gl/9Sy55kduybJoCEoGA"
        }}>{`金のうんこ`}</a>{` (Golden turd)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/btRrufHQ4FxFjhuH9"
        }}>{`東京スカイツリー (Tokyo Skytree)`}</a>{` - Walking distance!`}</li>
    </ul>
    <h3 {...{
      "id": "銀座-ginza"
    }}>{`銀座 (Ginza)`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/HvMdvKSvrF5pZQaDA"
        }}>{`築地場外市場 (Tsukiji Outer Market)`}</a>{` - Used to be a Tuna auction early morning and other happenings but things have changed so check their `}<a parentName="li" {...{
          "href": "https://www.tsukiji.or.jp/"
        }}>{`website`}</a>{` for updates`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/5jSTYYKB5UH2"
        }}>{`すしざんまい 本店 (Sushizanmai Honten)`}</a>{` - Gets very crowded`}</li>
    </ul>
    <h3 {...{
      "id": "池袋-ikebukuro"
    }}>{`池袋 (Ikebukuro)`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/gWBfUbjn2UwXeAJU8"
        }}>{`いけふくろう (Ikefukuro)`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Inside the train station but this whole area is themed with Owls`}</li>
          <li parentName="ul">{`It's a play on the words for "Fukuro" (meaning Owl) and "Ikebukuro"`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/yLpQyk9AT2iw6KT8A"
        }}>{`サンシャインシティ (Sunshine City)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/hcEw8fFMPcUsgeMTA"
        }}>{`ばくだん焼本舗 (Bakudan-yaki Honpo)`}</a>{` - Not the typical size with lots of filling`}</li>
    </ul>
    <h3 {...{
      "id": "お台場-odaiba"
    }}>{`お台場 (Odaiba)`}</h3>
    <ul>
      <li parentName="ul">{`For the views, ride in the first train car when heading towards here`}</li>
      <li parentName="ul">{`Palette Town closed after 20+ years so I'm not really sure what's here anymore...`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/cGmvQNrraun437nN6"
        }}>{`自由の女神像`}</a>{` - Statue of Liberty replica`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/AAxZgxKH7Gt1YCm66"
        }}>{`レインボーブリッジ`}</a>{` - Rainbow bridge is rainbow at night`}</li>
    </ul>
    <h3 {...{
      "id": "六本木-roppongi--汐留-shiodome"
    }}>{`六本木 (Roppongi) & 汐留 (Shiodome)`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/Gxt3BYmzycuv1Rzf9"
        }}>{`国立新美術館 (National Art Center)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/MrU9beKfhUyGvNcS9"
        }}>{`東京タワー (Tokyo Tower)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/qyFyizu7CpJwAZuu9"
        }}>{`増上寺 (Zojoji)`}</a></li>
    </ul>
    <h3 {...{
      "id": "渋谷区-shibuya--新宿区-shinjuku"
    }}>{`渋谷区 (Shibuya) & 新宿区 (Shinjuku)`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/SEJhmPoaJuhK3h1b6"
        }}>{`忠犬ハチ公像 (Hachiko Statue)`}</a>{` - Famous `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Hachik%C5%8D"
        }}>{`backstory`}</a>{` and a popular meeting spot`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/ukSYM8YdKs5ut6uG7"
        }}>{`渋谷スクランブル交差点 (Shibuya Scramble Crossing)`}</a>{` - One of the better spots to view the chaos is inside the train station (as you walk towards the exit, impossible to miss) since it's elevated`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/pZpuMwYJhr9EMX5R8"
        }}>{`SHIBUYA 109`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/v3MUPHsKnAZhoE6v7"
        }}>{`ワタリウム美術館 (Watarium Art Museum)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/qZHukrokrNdzY1N88"
        }}>{`代々木公園 (Yoyogi Park)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/RCUEDKhQfjaC4Bu56"
        }}>{`すずや (Suzuya)`}</a>{` - Try the Tonkatsu Chazuke`}</li>
    </ul>
    <h4 {...{
      "id": "原宿-harajuku"
    }}>{`原宿 (Harajuku)`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/4ZoY2fSeKB3gTWfN9"
        }}>{`竹下通り (Takeshita Street)`}</a>{` - Shopping and lots of fashionable crepes`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/6CKNXSJosXxeE4wR8"
        }}>{`Marion Crêpes`}</a></li>
    </ul>
    <h2 {...{
      "id": "kanto---kanagawa"
    }}>{`Kanto - Kanagawa`}</h2>
    <h3 {...{
      "id": "江の島-enoshima"
    }}>{`江の島 (Enoshima)`}</h3>
    <ul>
      <li parentName="ul">{`You can get here by riding the `}<a parentName="li" {...{
          "href": "https://www.enoden.co.jp/en/"
        }}>{`Enoshima Electric Railway`}</a>{` from Kamakura.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.murasaki-imo.com/"
        }}>{`Asahi`}</a>
        <ul parentName="li">
          <li parentName="ul">{`They sell a giant pressed Senbei! My friend and I waited on a very long line for this. We probably waited a whole hour.`}</li>
          <li parentName="ul">{`They are famous for pressing octopus into Maruyaki Tako Senbei, ¥350.`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://enoshimajinja.or.jp/"
        }}>{`Enoshima Shrine`}</a>{` - Free entrance unless you want to see the Benten statue.`}</li>
      <li parentName="ul">{`There are Mt. Fuji viewing spots by the bridge.`}</li>
    </ul>
    <h3 {...{
      "id": "鎌倉-kamakura"
    }}>{`鎌倉 (Kamakura)`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://imoyoshi.com/"
        }}>{`いも吉館`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Murasaki Imo & Matcha mix soft serve. Eat this.`}</li>
          <li parentName="ul">{`They sell other Omiyage (souvenirs) like Murasaki Imo flavored Baumkuchen!`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Dango shops!`}
        <ul parentName="li">
          <li parentName="ul">{`I like `}<a parentName="li" {...{
              "href": "https://en.wikipedia.org/wiki/Mitarashi_dango"
            }}>{`Mitarashi`}</a>{` flavor (soy sauce glaze) the most.`}</li>
          <li parentName="ul">{`I tried the flavor Sakura Mitarashi for science but no.`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.hato.co.jp/index.html"
        }}>{`Hato Sable`}</a>
        <ul parentName="li">
          <li parentName="ul">{`This is, for whatever reason, a classic souvenir to buy - Dove shaped cookies.`}</li>
          <li parentName="ul">{`They also sell other traditional Japanese sweets as well based on the season.`}</li>
        </ul>
      </li>
    </ul>
    <h4 {...{
      "id": "things-to-do"
    }}>{`Things to do`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.kotoku-in.jp/"
        }}>{`Kotoku-in (高徳院)`}</a>{` - Also known as the Great Buddha of Kamakura`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.hachimangu.or.jp/"
        }}>{`Tsurugaoka Hachimangu (鶴岡八幡宮)`}</a>{` - The most important Shinto shine here.`}</li>
    </ul>
    <h3 {...{
      "id": "横浜市-yokohama"
    }}>{`横浜市 (Yokohama)`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/jEnmWmtW1oDSuMpc8"
        }}>{`Costco`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/5QstgMgZWSRCqriX9"
        }}>{`コスモクロック21 (Cosmo Clock 21)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/pLJ32TBoKh837bsx5"
        }}>{`スカイウォーク (Skywalk)`}</a>{` - I believe they reopened!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/ShaRm6Q1TQWWtbVr7"
        }}>{`中華街 (Chinatown)`}</a>{` - Largest one in Japan`}</li>
    </ul>
    <h3 {...{
      "id": "箱根町-hakone"
    }}>{`箱根町 (Hakone)`}</h3>
    <ul>
      <li parentName="ul">{`Hot springs`}</li>
    </ul>
    <h2 {...{
      "id": "kansai---kyoto"
    }}>{`Kansai - Kyoto`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/O-mikuji"
        }}>{`Omikuji`}</a>{` - Get your fortune at the temples!`}</li>
    </ul>
    <h3 {...{
      "id": "京都市-kyoto"
    }}>{`京都市 (Kyoto)`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/XxqiLonXsq9jpfX19"
        }}>{`三十三間堂 (Sanjusangendo)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/zqBEsg3zAUhjHyKJ9"
        }}>{`元離宮二条城 (Nijō Castle)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/PjtRYXQCVg4DXk266"
        }}>{`東本願寺 (Higashi Honganji)`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/DSDgW1iRAcBCaRP5A"
            }}>{`渉成園 (Shoseien)`}</a>{` - Detached and known as Kikoku-tei`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/NrrmQJDhCDT44MUJ9"
            }}>{`京都タワー (Kyoto Tower)`}</a>{` - Walking distance`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/VJJ3urTDPxF15yWj7"
        }}>{`清水寺 (Kiyomizudera)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/HDsh6rnjTofGXqFg9"
        }}>{`知恩院 (Chionin)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/S71rfiEdos51tPmZA"
        }}>{`金閣寺 (Kinkakuji)`}</a>{` - Golden pavilion`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/3WEtrYaygM8wHuJ17"
        }}>{`銀閣寺 (Ginkakuji)`}</a>{` - Silver pavilion`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/nvjpCeYz446EGUrm9"
        }}>{`龍安寺 (Ryoanji)`}</a></li>
    </ul>
    <h2 {...{
      "id": "kansai---osaka"
    }}>{`Kansai - Osaka`}</h2>
    <h3 {...{
      "id": "大阪市-osaka"
    }}>{`大阪市 (Osaka)`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.osp.osaka-info.jp/en/"
        }}>{`Osaka Pass`}</a>{` - Covers most of the popular spots at a great value`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/PjdejHhYqeYHxtWz5"
        }}>{`道頓堀 (Dotonbori)`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Very busy area for food and shopping`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/pf9Q1G4paSqPNHfb6"
            }}>{`かに道楽 (Kani Doraku)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/qxcLc4LDTyrpA1naA"
            }}>{`グリコサイン (Glica Man)`}</a>{` - Famous sign!`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/3xXYnVSqW6haYWvXA"
        }}>{`大阪城天守閣 (Osaka castle)`}</a>{` - Reconstructed as most castles are in Japan`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/irFCsi9ByJEwPSQJ7"
        }}>{`天保山大観覧車 (Temponzan Ferris Wheel)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/APHoebEQxvnaGhhs8"
        }}>{`梅田スカイビル (Umeda Sky Building)`}</a>{` - Floating garden observatory at night is nice but the line might be really long`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/P96QvxVCBkrteoYYA"
        }}>{`海遊館 (Kaiyukan)`}</a>{` - Really nice`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/ydq7k8nzQD4zpFfi8"
        }}>{`通天閣 (Tsutenkaku)`}</a>{` - Umeda is a bit nicer between the two, for me`}</li>
    </ul>
    <h2 {...{
      "id": "kansai---hyogo"
    }}>{`Kansai - Hyogo`}</h2>
    <h3 {...{
      "id": "神戸市-kobe"
    }}>{`神戸市 (Kobe)`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/HZe1URQ2CLyU6uGa8"
        }}>{`北野町 (Kitano-Cho)`}</a>{` - Area full of `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Kitano-ch%C5%8D"
        }}>{`異人館 (western-style mansions)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/RhDnhhpB7aeXz5ZU6"
        }}>{`南京町 (Nankinmachi)`}</a>{` - Chinatown of the Kansai region`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/iyXBJNDyMqkLj35d9"
        }}>{`生田神社 (Ikuta Shrine)`}</a></li>
    </ul>
    <h2 {...{
      "id": "chubu---ishikawa"
    }}>{`Chubu - Ishikawa`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Jibu-ni"
        }}>{`じぶ煮 (Jibuni)`}</a>{` - Regional dish with seasoned duck, chicken, or seafood (crab)`}</li>
      <li parentName="ul">{`金沢カレー (Kanazawa Curry) - Regional speciality`}
        <ul parentName="li">
          <li parentName="ul">{`Curry is thicker and richer, served with shredded cabbage in a steel bowl with a spork. The cutlet has a drizzle of tonkatsu sauce and is placed directly over the curry that covers the rice.`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "金沢市-kanazawa"
    }}>{`金沢市 (Kanazawa)`}</h3>
    <h4 {...{
      "id": "food-1"
    }}>{`Food`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/Xow3FhpyHQGMmHRb6"
        }}>{`ゴールドカレー (Gold Curry)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/88CFPKXy2ov2hHdt5"
        }}>{`チャンピオンカレー (Champion’s Curry)`}</a>{` - Founder invented this curry edition`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/wyWCtqKzLG5gK7ZZ8"
        }}>{`居酒屋ちょん兵衛 (Izakaya Chombee)`}</a>{` - Right by the train station`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/qAoiCF1a5mWz8CBr7"
        }}>{`鮨一平 (Sushi Ippei)`}</a>{` - Owned by a lovely couple who spoke and only opened for dinner. Tucked in an alley so might be hard to find.`}</li>
    </ul>
    <h4 {...{
      "id": "things-to-do-1"
    }}>{`Things to do`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/G9HRUwxLEU55Bg7k7"
        }}>{`ひがし茶屋街 (Higashi Chayagai) `}</a>{` - Eastern, most popular`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/b5soGdjsuwBDyDd66"
            }}>{`Ochaya SHIMA`}</a>{` - Feudal era teahouse that converted to a museum.`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/1c6brmTLnorrD7NS6"
        }}>{`にし茶屋街 (Nishi Chayagai)`}</a>{` - Western`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/64awwvmfQyfDJLsv9"
        }}>{`主計町茶屋街 (Kazuemachi Chayagai)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/X5iPgRHoJzSh7cjaA"
        }}>{`兼六園 (Kenrokuen)`}</a>{` - One of the Three Great Gardens of Japan`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/Xrcwax86fnMbcVbd7"
        }}>{`金沢21世紀美術館 (21st Century Museum)`}</a>{` - Swimming Pool exhibit was cool and other areas didn’t allow photography`}</li>
    </ul>
    <h2 {...{
      "id": "chubu---toyama"
    }}>{`Chubu - Toyama`}</h2>
    <h3 {...{
      "id": "黒部峡谷-kurobe-gorge"
    }}>{`黒部峡谷 (Kurobe Gorge)`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/XpixsERVSMVJpsyNA"
        }}>{`黒部峡谷トロッコ列車 (Kurobe Gorge Railway)`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Located at `}<a parentName="li" {...{
              "href": "https://maps.app.goo.gl/4kTyYw247Ak9yhXC6"
            }}>{`Unazuki Station`}</a>{` which is about a 5 minute walk from `}<a parentName="li" {...{
              "href": "https://maps.app.goo.gl/zh5VHsn2qDfkZ2sY6"
            }}>{`宇奈月温泉駅 (Unazukionsen Station)`}</a></li>
          <li parentName="ul">{`This is a sightseeing train (with stops) that was used to aid the construction of Kurobe Dam. The railway is ~20km. Sit on the right side for the better view when going in.`}</li>
          <li parentName="ul">{`Check the schedule as it's not opened all year round`}</li>
        </ul>
      </li>
    </ul>
    <h4 {...{
      "id": "stations"
    }}>{`Stations`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/4kTyYw247Ak9yhXC6"
        }}>{`宇奈月駅 (Unazuki Station)`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/hoaJQK5hdoqCZcEi7"
            }}>{`新山彦橋`}</a>{` - Shinyamabiko Bridge`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/Ugkr4HsSombgaQx16"
            }}>{`黒部川電気記念館`}</a>{` - Kurobe River Electricity Memorial Hall`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/CMPBUTizAwRA6kMbA"
        }}>{`黒薙駅 (Kuronagi Station)`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/m1XSRZDGaTBPaVpT6"
            }}>{`後曳橋`}</a>{` - Atobiki Bridge`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/ZqpfLSM8pV2gjWuz9"
        }}>{`鐘釣駅 (Kanetsuri Station)`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/WsNX4xyoV6CihDtT6"
            }}>{`万年雪展望台`}</a>{` - Mannenyuki Overvation Deck`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/QVnwerumw8xrsyk5A"
        }}>{`欅平駅 (Keyakidaira Station)`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/9Bz4cmxyt56FQ4987"
            }}>{`人喰岩`}</a>{` - Man-eating rock`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/DS85DgUkVgsrMGbCA"
            }}>{`奥鐘橋`}</a>{` - Okukane Bridge`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/grH87skpJUyuxio38"
            }}>{`猿飛峡`}</a>{` - Sarutobi Canyon`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "chubu---shizuoka"
    }}>{`Chubu - Shizuoka`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/i9wkkywKBs71tohC7"
        }}>{`かつ政 (Katsumasa)`}</a>{` - Katsu's good, has multiple branches`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/QAPuoHHDhrzxhh2X8"
        }}>{`御殿場プレミアム・アウトレット`}</a>{` - Gotemba Premium Outlets has amazing views of Mt. Fuji in the background`}</li>
    </ul>
    <h3 {...{
      "id": "三島市-mishima"
    }}>{`三島市 (Mishima)`}</h3>
    <ul>
      <li parentName="ul">{`Mt. Fuji is visible from many places and town was very walkable`}</li>
    </ul>
    <h4 {...{
      "id": "food-2"
    }}>{`Food`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/ibshmBd889E7qM3z7"
        }}>{`田子の月 (Taga no Tsuki)`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Famous confectionery shop selling very cute snacks`}</li>
          <li parentName="ul">{`富士山頂 (Fujisancho) is shaped like a little Mt. Fuji!`}</li>
        </ul>
      </li>
    </ul>
    <h4 {...{
      "id": "things-to-do-2"
    }}>{`Things to do`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/yYAAv1wMnPvQpjjv9"
        }}>{`三嶋大社 (Mishima Taisha)`}</a>{` - Shinto shrine`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/8N3nZvGukwKmbLh5A"
        }}>{`楽寿園 (Rakujuen Park)`}</a>{` - Lots of animals roaming`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.sanobi.or.jp/eng/"
        }}>{`佐野美術館 (Sano Art Museum)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/FauUMNNbJK8ook7U6"
        }}>{`源兵衛橋 Genbee Bridge`}</a>{` - Saw many ducks`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.suntomoon.co.jp/"
        }}>{`サントムーン (SUN TO MOON)`}</a>{` - Local shopping mall`}</li>
    </ul>
    <h3 {...{
      "id": "富士山-mount-fuji"
    }}>{`富士山 (Mount Fuji)`}</h3>
    <ul>
      <li parentName="ul">{`Buy Wasabi flavored kit kats!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/1HADqvicf1rY9rP57"
        }}>{`富士急ハイランド (Fuji-Q Highland)`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Roller coasters are called jet coasters`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/kHKQSdFxhdy6F2e77"
            }}>{`ええじゃないか (Eejanaika)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/akZRJ81zoxLnR2Py8"
            }}>{`ドドンパ (Dodonpa)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/4rETFL4JGQGo37dEA"
            }}>{`Fujiyama`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/Fx1MZ5e3JNyRJyiT6"
        }}>{`ふじやま温泉 (Fujiyama Onsen)`}</a>{` - Traditional hot springs`}</li>
    </ul>
    <h2 {...{
      "id": "hokkaido"
    }}>{`Hokkaido`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://en.wikipedia.org/wiki/Jingisukan"
        }}>{`ジンギスカン (Jingisukan)`}</a>{` - Regional dish`}
        <ul parentName="li">
          <li parentName="ul">{`Mutton yakiniku named after "Genghis Khan"`}</li>
          <li parentName="ul">{`Usually cooked on a Mongolian grill`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Northern Ramen styles`}
        <ul parentName="li">
          <li parentName="ul">{`Sapporo - Birthplace of Miso ramen. Sometimes with a slice of butter.`}</li>
          <li parentName="ul">{`Hakodate - Shio (salt) based with medium noodles`}</li>
          <li parentName="ul">{`Ashikawa - Shoyu broth with thin and wavy noodles`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "函館市-hakodate"
    }}>{`函館市 (Hakodate)`}</h3>
    <h4 {...{
      "id": "food-3"
    }}>{`Food`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/bWS3QsfMFJDfsF119"
        }}>{`函太郎 (Kantaro Sushi)`}</a>{` - Conveyor belt sushi restaurant by the water`}</li>
    </ul>
    <h4 {...{
      "id": "things-to-do-3"
    }}>{`Things to do`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/THXN3cMcsrG32UHP7"
        }}>{`函館山ロープウェイ山麓 (Mt. Hakodate Ropeway)`}</a>{` - Famous for night view`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/M5edXhkePct1ZrQv7"
        }}>{`五稜郭タワー (Goryokaku Tower)`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Observation deck at 90m but difficult to take photos`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/aamg7pVuJHCWYq397"
            }}>{`五稜郭跡`}</a>{` - Inspired by the French star-shaped forts`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://en.wikipedia.org/wiki/Hanami"
            }}>{`花見`}</a>{` - Popular area for flower viewing during cherry blossom season`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/CxyqbqTLV4RVESbN8"
        }}>{`金森赤レンガ倉庫`}</a>{` - Kanemori Red Brick Warehouse area lights up at night`}</li>
    </ul>
    <h3 {...{
      "id": "大沼公園-onumakoen"
    }}>{`大沼公園 (Onumakoen)`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/mvMp1v7kTVrZeRWv6"
        }}>{`大沼国定公園`}</a>{` - Onuma Quasi-National Park`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/wgdTy1rEsioMSkgJA"
        }}>{`沼の家 (Numa no ya)`}</a>{` - Highly recommend the famous Dango!! Since 1905!`}
        <ul parentName="li">
          <li parentName="ul">{`あんと正油 (Red bean paste with soy sauce)`}</li>
          <li parentName="ul">{`胡麻と正油 (Sesame with soy sauce) - Smaller production`}</li>
          <li parentName="ul">{`Fresh milk - Requires a deposit for the jar which can be returned`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/qCwnM4HwP1QZxKsx8"
        }}>{`谷口菓子舗 (Taniguchi Dango Shop)`}</a>{` - They sell a 三色折 (3 color discount) which includes Red bean paste, Sesame, and Soy sauce.`}</li>
    </ul>
    <h3 {...{
      "id": "札幌市-sapporo"
    }}>{`札幌市 (Sapporo)`}</h3>
    <h4 {...{
      "id": "food-4"
    }}>{`Food`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/tEqWrBg8aPAi7UXt6"
        }}>{`けやき (Keyaki)`}</a>{` - Famous but tiny Ramen shop, long lines`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/b2h4D2XbNXziCXrV6"
        }}>{`すみれ (Sumire)`}</a>{` - Famous Ramen shop`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/Zok77nzpLHag2ixTA"
        }}>{`成吉思汗だるま (Daruma)`}</a>{` - Eat Jingisukan, long lines`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/uW7MzJkMFrve9M4L6"
        }}>{`KINOTOYA BAKE`}</a>{` - Cheese tart is a must`}</li>
    </ul>
    <h4 {...{
      "id": "things-to-do-4"
    }}>{`Things to do`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/Hwuict5AF9GtC6Wd8"
        }}>{`もいわ山ロープウェイ (Mt. Moiwa Ropeway)`}</a>{` - Night views`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/AbjtgqzaDfpKzb848"
        }}>{`北海道神宮 (Hokkaido Shrine)`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "http://www.sapporo.travel/event/summer/hokkaido_shrine_festival"
            }}>{`北海道神宮例大祭 (Hokkaido Shrine Festival)`}</a>{` - Summer only in mid June`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/aet5gPDnKjM9DW338"
            }}>{`円山公園 (Maruyama Park)`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/VgjxUPWS2BvGnwTh8"
        }}>{`大通公園 (Odori Park)`}</a>{` - Known as "Large street" and splits the north and south of the city`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/x5vUHqnYwUbdNrHn6"
            }}>{`さっぽろテレビ塔 (Sapporo TV Tower)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/iJKRDA4WsCf3sxoy8"
            }}>{`札幌市時計台 (Sapporo Clock Tower)`}</a></li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "小樽市-otaru"
    }}>{`小樽市 (Otaru)`}</h3>
    <ul>
      <li parentName="ul">{`Can be done as a daytrip from Sapporo`}</li>
    </ul>
    <h4 {...{
      "id": "food-5"
    }}>{`Food`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/2CNukUEV15j4aerj9"
        }}>{`伊勢鮨壽司 (Izesushi)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/xWgeHQfk6EXQhn317"
        }}>{`ミルク·プラント (Milk Plant)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/AbqPy2vHYDJ3EAe69"
        }}>{`山中牧場 (Yamanaka Dairy Farm)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/e6ER4MTGfDtMDokp9"
        }}>{`ルタオ (LeTAO)`}</a></li>
    </ul>
    <h4 {...{
      "id": "things-to-do-5"
    }}>{`Things to do`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/c3iDGQJwRpLMFYCj7"
        }}>{`オルゴール堂蒸気からくり時計 Otaru Steam Clock`}</a>{` - World’s largest steam clock`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/gFyxQe9jWnUESXAW7"
        }}>{`ステンドグラス美術館 (Stained Glass Museum)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/fcxwmEG8kZQ2Ner96"
        }}>{`北一硝子 (Kitaichi Glass)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/MDqd88fmeFLDT8QW9"
        }}>{`小樽オルゴール堂 (Otaru Music Box Museum)`}</a>
        <ul parentName="li">
          <li parentName="ul">{`"Orugoru" (Music box) is a loan word (Dutch) with `}<a parentName="li" {...{
              "href": "https://ja.wikipedia.org/wiki/%E3%82%AA%E3%83%AB%E3%82%B4%E3%83%BC%E3%83%AB"
            }}>{`fasinating history`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/j9tU9UCP2KnsnH6XA"
        }}>{`小樽運河 (Otaru Canal)`}</a></li>
    </ul>
    <h3 {...{
      "id": "旭川市-asahikawa"
    }}>{`旭川市 (Asahikawa)`}</h3>
    <ul>
      <li parentName="ul">{`Can be done as a daytrip from Sapporo`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/AxPL2pa4hzrLdPTX6"
        }}>{`らぅめん青葉 (Aoba Ramen)`}</a>{` - Asahikawa shoyu ramen since 1947!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/H8J7epUsSX2aVz9w5"
        }}>{`旭川市旭山動物園 (Asahikawa Zoo)`}</a>{` - Polar bear and Penguin exhibits`}</li>
    </ul>
    <h2 {...{
      "id": "chugoku"
    }}>{`Chugoku`}</h2>
    <h3 {...{
      "id": "広島市-hiroshima"
    }}>{`広島市 (Hiroshima)`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/2surqdsbJyfvtZb3A"
        }}>{`原爆ドーム (Atomic Bomb Dome)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/zDNxJdikaKYhPN2GA"
        }}>{`広島平和記念資料館 (Hiroshima Peace Memorial Museum)`}</a></li>
    </ul>
    <h3 {...{
      "id": "岩国市-iwakuni"
    }}>{`岩国市 (Iwakuni)`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/xNd5vVBFqFJJ79hF8"
        }}>{`錦帯橋 (Kintaikyo)`}</a>{` - One of the top 3 wooden bridges in Japan`}</li>
    </ul>
    <h3 {...{
      "id": "厳島-itsukushima"
    }}>{`厳島 (Itsukushima)`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/gNhjmbcfHj8A9Xk1A"
        }}>{`JR宮島フェリー (JR Miyajima Ferry)`}</a></li>
    </ul>
    <h4 {...{
      "id": "food-6"
    }}>{`Food`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/rinymAj2SCycp63M9"
        }}>{`游鹿里茶屋 (Yukarichaya)`}</a>{` - Lovely tea house but might be hard to find. The hill in the backyard has great views!`}</li>
    </ul>
    <h4 {...{
      "id": "things-to-do-6"
    }}>{`Things to do`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/N1N7Lx3d22y9pDdY9"
        }}>{`嚴島神社 (Itsukushima Jinja)`}</a>{` - When the tide is low enough in the evening, you can walk right up to the 鳥居 (Torii)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Mount_Misen"
        }}>{`弥山 (Mt. Misen)`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Recommend taking one of `}<a parentName="li" {...{
              "href": "https://www.miyajima.or.jp/course/course_tozan3.html"
            }}>{`3 foot paths`}</a>{` to wait out the tide change. The trials are well paved but the signage is a bit small.`}
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://maps.app.goo.gl/V9B9GpUeStcNEdCKA"
                }}>{`紅葉谷コース (Momijidani Course)`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://maps.app.goo.gl/QnqaztEWnKV2dKDu9"
                }}>{`大元コース (Omoto Course)`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://maps.app.goo.gl/jjv4bb7xbE9fhbbK9"
                }}>{`大聖院コ－ス (Daishoin Course)`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/5hv8cwi4Kix2cxAH7"
            }}>{`宮島ロープウエー (Miyajima Ropeway)`}</a>{` - Alternatively there is a cable car`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/nmY1s6H5Pg57VC6w9"
            }}>{`弥山 展望台 (Mount Misen Observatory)`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/baFEn6C2mW8PLAgc6"
        }}>{`紅葉谷公園 (Momijidani Park)`}</a>{` - Lots of deer walking around`}</li>
    </ul>
    <h2 {...{
      "id": "kyushu"
    }}>{`Kyushu`}</h2>
    <ul>
      <li parentName="ul">{`Sand bath experience - Famous in Ibusuki City`}</li>
    </ul>
    <h3 {...{
      "id": "福岡市-fukuoka"
    }}>{`福岡市 (Fukuoka)`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.jrbeetle.com/en/"
        }}>{`JR Beetle`}</a>{` - Ferry to/from Busan, Korea`}</li>
    </ul>
    <h3 {...{
      "id": "鹿児島市-kagoshima"
    }}>{`鹿児島市 (Kagoshima)`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ja.wikipedia.org/wiki/%E7%99%BD%E3%81%8F%E3%81%BE"
        }}>{`しろくま (Shirokuma)`}</a>{` - Regional frozen dessert speciality. It's a type of kakigori (shaved ice) popular in the south.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ja.wikipedia.org/wiki/%E3%81%8B%E3%81%94%E3%81%97%E3%81%BE%E9%BB%92%E8%B1%9A"
        }}>{`かごしま黒豚 (Kagoshima Kurobuta)`}</a>{` - Berkshire pork raised in Kagoshima Prefecture`}</li>
    </ul>
    <h4 {...{
      "id": "food-7"
    }}>{`Food`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/XjXaSuwtFEqucVEM6"
        }}>{`柿の木 (Kakinoki)`}</a>{` - Ramen shop`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/sozpeWPm37VfQUwF9"
        }}>{`天文館むじゃき (Tenmonkan Mujaki)`}</a>{` - Famous for Shirokuma!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/d5BYMZgGCbTVxDFPA"
        }}>{`味の六白 (Aji no Roppaku)`}</a>{` - Eat Kagoshima Kurobuta`}</li>
    </ul>
    <h4 {...{
      "id": "things-to-do-7"
    }}>{`Things to do`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/8vTs6m4NPRZN1gLm8"
        }}>{`天文館通 (Tenmonkan Street)`}</a></li>
    </ul>
    <h3 {...{
      "id": "桜島-sakurajima"
    }}>{`桜島 (Sakurajima)`}</h3>
    <ul>
      <li parentName="ul">{`Can be done as a daytrip from Kagoshima. There's a ferry at Kagoshima Port.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Sakurajima"
        }}>{`Active Volcano`}</a>{` - Recommend wearing close toe shoes`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/Jyr4fxagVAgG4qNB7"
        }}>{`桜島ビジターセンター`}</a>{` - Sakurajima Visitor Center has all the information you need`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/A1BZabdbqiqZEAPz5"
        }}>{`湯之平展望所 (Yunohira Observation Deck)`}</a></li>
    </ul>
    <h2 {...{
      "id": "shikoku"
    }}>{`Shikoku`}</h2>
    <h3 {...{
      "id": "高知県-kochi"
    }}>{`高知県 (Kochi)`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ja.wikipedia.org/wiki/%E9%B0%B9%E3%81%AE%E3%82%BF%E3%82%BF%E3%82%AD"
        }}>{`鰹のタタキ (Katsuo no Tataki)`}</a>{` - Regional speciality, seared bonito`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ja.wikipedia.org/wiki/%E3%82%A2%E3%82%A4%E3%82%B9%E3%82%AF%E3%83%AA%E3%83%B3"
        }}>{`アイスクリン (Aisukurin)`}</a>{` - Regional speciality, ice cream`}</li>
    </ul>
    <h4 {...{
      "id": "food-8"
    }}>{`Food`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/TP6kgDwHfjAw7hA49"
        }}>{`ひろめ市場 (Hirome Market)`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/mDhyH3rz8bHcmdRf6"
            }}>{`明神丸 (Myojinmaru)`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/zLPjTu5nSWpjKMAcA"
        }}>{`ひだまり小路 土佐茶カフェ (Hidamarikoji Tosa-cha)`}</a>{` - Cute cafe`}</li>
    </ul>
    <h4 {...{
      "id": "things-to-do-8"
    }}>{`Things to do`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/DwHaGTLDhQuT971L7"
        }}>{`高知城 (Kochi Castle)`}</a>{` - This castle is the original building but not too interesting. The entrance fee was around ¥500.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/nLHBmnRBqg5CxFry8"
        }}>{`はりまや橋 (Harimayabashi Bridge)`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/ZqNkbQqn96go2eQh9"
            }}>{`からくり時計 (Karakuri Clock)`}</a>{` - Tells the story about a star-crossed love between a Buddhist monk and a merchant’s daughter who was later forced to flee. The clock show is hourly.`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/121xvRSbkGJaNMyt6"
            }}>{`アンパンマン像 (Anpanman Statue)`}</a></li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "いの町-ino"
    }}>{`いの町 (Ino)`}</h3>
    <ul>
      <li parentName="ul">{`Can be done as a daytrip from Kochi`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/58RKSrmjptAGs2fp7"
        }}>{`紙の博物館 (Paper Museum)`}</a>{` - I did the “Tosa Washi workshop” which was to make paper. It lasted around an hour and I used the paper later on as postcards. No spoken English in 2013.`}</li>
    </ul>
    <h3 {...{
      "id": "琴平町-kotohira"
    }}>{`琴平町 (Kotohira)`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Sanuki_udon"
        }}>{`讃岐うどん (Sanuki Udon)`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Noodles are square shaped with flat edges. It was very famous in Kagawa Prefecture. I prefer the cold version with a soft-boiled egg.`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/iAziyqgr3aC5sKkRA"
            }}>{`こんぴらうどん (Kompira udon)`}</a>{` - Famous, traditional, and delicious`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/R9iJ7viS68iYUE6u5"
        }}>{`こんぴらさん (Konpira-san)`}</a>{` - On the slope of Mount Zozu. About 1368 steps to the inner shrine.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      